<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader> <strong>Add Product Group</strong> </CCardHeader>
          <CForm autocomplete="off" @submit.prevent="submit">
            <CCardBody>
              <CRow>
                <CCol sm="12">
                  <CInput
                    type="text"
                    autocomplete="off"
                    label="Group Name"
                    v-model="name"
                    placeholder="Enter product group name..."
                    required
                    @keyup="check_name"
                    :addInputClasses="name_invalid ? 'is-invalid' : null"
                    :isValid="name ? true : null"
                  />
                </CCol>              
              </CRow>
              <CRow>
                <CCol sm="12">
                  <CTextarea
                    label="Description"
                    v-model="description"
                    placeholder="Enter description..."                   
                  />
                </CCol>             
              </CRow>              
            </CCardBody>
            <CCardFooter>
              <CButtonToolbar class="justify-content-right">
                <CButton
                  size="sm"
                  color="primary"
                  type="submit"
                  v-if="!isSaving"
                  ><CIcon name="cil-check-circle" /> Submit</CButton
                >
                <CButton color="secondary" class="px-4" disabled v-else>
                  <CSpinner grow size="sm" /> Saving..
                </CButton>
              </CButtonToolbar>
            </CCardFooter>
          </CForm>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ProductGroupCreate",
  data() {
    return { 
      name: null,
      description: null,      
      name_invalid: false    
    };
  },
  created() {
    if (!this.$store.state.access_token) {
      this.$router.push({ name: "Login" });
    }
  },
  computed: {
    ...mapState({
      isSaving: (state) => state.product_groups.isSaving,
    }),
  },
  methods: {
    check_name() {
      if (!this.name) {
        this.name_invalid = true;
      } else {
        this.name_invalid = false;
      }
    }, 
    checkForm() {
      if (!this.name) {    
        this.check_name();   
      } else {
        return true;
      }
    },
    submit() {
      if (this.checkForm()) {
        this.$store
          .dispatch("product_groups/saveProductGroup", {
            Authorization: "bearer " + this.$store.state.access_token,
            name: this.name, 
            description: this.description, 
          })
          .then((response) => {
            this.clear_form();
          });
      } else {
        this.checkForm();
      }
    },
    clear_form() {
      this.description = null;
      this.name = null; 
    },
  },
};
</script>
 